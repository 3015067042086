<template>
  <v-card>
    <v-card-title>
      Create New Task <v-spacer />
      <v-btn icon color="red" title="close" @click="handle_close">
        <v-icon>mdi-close</v-icon>
      </v-btn></v-card-title
    >

    <v-card-text class="mt-5">
      <v-form v-model="valid_form" ref="form">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="new_task.subject"
                  :rules="[rule.required]"
                  hide-details="auto"
                  label="Subject"
                  dense
                  outlined
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="new_task.day_limit"
                  :rules="[rule.required]"
                  hide-details="auto"
                  label="Day Limit"
                  type="number"
                  dense
                  outlined
                />
              </v-col>
              
              <v-col>
                <v-text-field
                  v-model="new_task.order"
                  :rules="[rule.required]"
                  hide-details="auto"
                  label="Order"
                  type="number"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col>
            <v-textarea
              v-model="new_task.description"
              hide-details="auto"
              label="Description"
              dense
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="new_task.selected_assignees"
              :items="assignees"
              item-value="id"
              item-text="username"
              :rules="[rule.required]"
              label="Assignee"
              multiple
              dense
              outlined
            />
          </v-col>

          <v-col></v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-divider />

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="teal" @click="addTask" text> submit </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import API from "@/plugins/API";
import rule from "@/plugins/rule";

export default {
  props: ["view_type", "task_data"],

  data: () => ({
    loading: false,
    valid_form: false,
    assignees: [],
    rule: rule,

    new_task: {
      subject: null,
      description: null,
      order: null,
      day_limit: null,
      selected_assignees: [],
    },
  }),

  methods: {
    addTask() {
      console.log(this.new_task);
      const valid = this.$refs.form.validate();

      if (!valid) {
        alert("Please fill all required fields");
        return;
      }

      this.$emit("add_task", this.new_task);
    },

    handle_close() {
      this.$emit("close");

      // reset form
      this.new_task = {
        subject: null,
        description: null,
        order: null,
        day_limit: null,
        selected_assignees: [],
      };

      this.valid_form = false;

      this.$refs.form.resetValidation();
    },

    async load_page_data() {
      try {
        this.loading = true;
        const res_users = await API().get("api/kanban/get_internal_users");

        [this.assignees] = [res_users.data];

        if (res_users) this.loading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },

  mounted() {
    this.load_page_data();
  },
};
</script>
