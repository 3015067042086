<template>
  <v-container>
    <v-row v-if="pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <h3 class="text-center">
          <v-progress-circular indeterminate class="ml-3" color="primary" />
          Getting data . . .
        </h3>
      </v-col>
    </v-row>

    <v-alert
      v-model="objective_status_is_submitted"
      transition="fade-transition"
      type="success"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="objective_status_is_submitted = false"
        >mdi-close</v-icon
      >
      <div class="mr-10 text-subtitle-1">Successfully Updated</div>
    </v-alert>

    <v-alert
      v-model="error"
      transition="fade-transition"
      type="error"
      style="z-index: 2; position: fixed; right: 15px"
      max-width="500px"
    >
      <v-icon class="float-right" @click="error = false">mdi-close</v-icon>
      <div class="mr-10 text-subtitle-1">
        {{ errorMessage }}
      </div>
    </v-alert>

    <v-row v-if="!pageIsLoading" justify="center">
      <v-col xs="12" xl="8">
        <v-row>
          <v-col>
            <v-breadcrumbs
              :items="[
                {
                  text: 'Deal',
                  disabled: false,
                  href: `/dashboard/admin/deal/read/${stg_obj.stg_itm.deal.id}`,
                },

                {
                  text: `Objective - ${$options.filters.capitalize_first_letter(
                    stg_obj.subject
                  )}`,
                  disabled: true,
                  href: `/dashboard/admin/kanban/Deal/stage-item/${stg_obj.stg_itm.id}/objective/read/${stg_obj.id}`,
                },
              ]"
              divider="/"
              large
            >
            </v-breadcrumbs>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-expansion-panels v-model="panel" multiple>
              <v-expansion-panel class="bordered">
                <v-expansion-panel-header class="d-flex align-center">
                  <div>
                    <v-icon>mdi-bullseye-arrow</v-icon> Objective Information
                  </div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <p class="grey--text">
                    You cannot change the status to closed if all tasks
                    are not yet done.
                  </p>

                  <v-alert
                    class="mt-5"
                    v-show="hasChanges"
                    color="orange darken-3"
                    dense
                    transition="fade-transition"
                  >
                    <div>
                      <v-icon>mdi-information-outline</v-icon>
                      <span class="ml-2">
                        The following changes will only be
                        applied after clicking the update
                        button.
                      </span>
                    </div>
                  </v-alert>

                  <v-simple-table>
                    <thead>
                      <tr>
                        <th class="white--text text-center" :width="200">
                          FIELDS
                        </th>
                        <th class="white--text text-center">DETAILS</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>OBJECTIVE NUMBER</td>
                        <td class="pl-7">
                          <v-label>{{ stg_obj.id }}</v-label>
                        </td>
                      </tr>

                      <tr>
                        <td>SUBJECT</td>
                        <td>
                          <v-text-field
                            v-model="stg_obj.subject"
                            flat
                            solo
                            dense
                            hide-details
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>DESCRIPTION</td>
                        <td>
                          <v-textarea
                            v-model="stg_obj.description"
                            rows="1"
                            flat
                            solo
                            dense
                            hide-details
                            auto-grow
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>DAYS PASSED</td>
                        <td class="pl-7">
                          <v-label>
                            {{ stg_obj_count }}
                            {{ stg_obj_count > 1 ? "days" : "day" }}</v-label
                          >
                        </td>
                      </tr>

                      <tr>
                        <td>DUE DATE</td>
                        <td>
                          <v-text-field
                            v-model="stg_obj.new_day_limit"
                            type="date"
                            flat
                            solo
                            dense
                            hide-details
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>ACTIVITY DATE</td>
                        <td>
                          <v-text-field
                            v-model="stg_obj.activity_date"
                            type="date"
                            flat
                            solo
                            dense
                            hide-details
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>STATUS</td>
                        <td>
                          <v-select
                            v-model="stg_obj.status"
                            :items="
                              status_options.filter((el) => {
                                if (
                                  stg_obj.stg_obj_tsks.some(
                                    (el) => el.status !== 'Done'
                                  )
                                ) {
                                  return el !== 'Closed';
                                } else {
                                  return el;
                                }
                              })
                            "
                            class="my-2"
                            dense
                            flat
                            hide-details
                            solo
                          />
                        </td>
                      </tr>

                      <tr>
                        <td>TYPE</td>
                        <td>
                          <v-select
                            v-model="stg_obj.type"
                            :items="['Internal Step', 'Service Ticket']"
                            @change="
                              stg_obj.service_ticket_type = null;
                              stg_obj.specified_service_ticket_type = null;
                            "
                            class="my-2"
                            dense
                            flat
                            hide-details
                            solo
                          />
                        </td>
                      </tr>

                      <tr v-if="stg_obj.type === 'Service Ticket'">
                        <td>SERVICE TICKET TYPE</td>
                        <td>
                          <v-select
                            v-model="stg_obj.service_ticket_type"
                            :items="[
                              'Payout Request',
                              'Balance Request',
                              'Payment Amount Change',
                              'Payment Date Change',
                              'Payment Collection',
                              'Property Taxes',
                              'Insurance',
                              'Demand Letter',
                              'Foreclosure',
                              'Other',
                            ]"
                            @change="
                              stg_obj.specified_service_ticket_type = null
                            "
                            class="my-2"
                            dense
                            flat
                            hide-details
                            solo
                          />
                        </td>
                      </tr>

                      <tr
                        v-if="
                          stg_obj.service_ticket_type === 'Other' &&
                          stg_obj.type === 'Service Ticket'
                        "
                      >
                        <td>SPECIFIED SERVICE TICKET TYPE</td>
                        <td>
                          <v-text-field
                            v-model="stg_obj.specified_service_ticket_type"
                            class="my-2"
                            dense
                            flat
                            hide-details
                            solo
                          />
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>

                  <div class="mt-5">
                    <v-btn
                      class="mr-2"
                      :disabled="!hasChanges || objective_status_is_updating"
                      @click="handle_update_objective"
                      color="teal"
                    >
                      <v-progress-circular
                        v-show="objective_status_is_updating"
                        class="mr-2"
                        color="primary"
                        indeterminate
                      />
                      Update
                    </v-btn>

                    <v-btn @click="$router.go(-1)"> cancel </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel class="bordered">
                <v-expansion-panel-header>
                  <div><v-icon>mdi-clipboard-text-outline</v-icon> Tasks</div>
                </v-expansion-panel-header>

                <v-expansion-panel-content>
                  <v-row>
                    <v-col>
                      <v-data-table
                        :headers="task_headers"
                        :items="stg_obj.stg_obj_tsks"
                        :items-per-page="-1"
                      >
                        <template v-slot:item.description="{ item }">
                          <div class="text-wrap">{{ item.description }}</div>
                        </template>

                        <template v-slot:item.day_count="{ item }">
                          {{ getDays(item.createdAt) }}
                        </template>

                        <template v-slot:item.new_day_limit="{ item }">
                          {{ formatDate(item.new_day_limit) }}
                        </template>

                        <template v-slot:item.status="{ item }">
                          <v-select
                            v-model="item.status"
                            :items="task_status_options"
                            @change="updateTaskStatus(item.id, item.status)"
                            dense
                            flat
                            hide-details
                            solo
                          />
                        </template>

                        <template v-slot:item.action="{ item }">
                          <div class="d-flex align-center">
                            <v-btn
                              @click="
                                update_task = true;
                                temp_task_id = item.id;
                              "
                              color="teal"
                              title="Edit Task"
                              icon
                            >
                              <v-icon> mdi-pencil-outline </v-icon>
                            </v-btn>

                            <v-btn
                              @click="handle_delete_task(item.id)"
                              color="red"
                              title="Delete Task"
                              icon
                            >
                              <v-icon> mdi-trash-can-outline </v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col>
                      <v-btn @click="openAddTaskForm = true"> Add Task </v-btn>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-row class="mt-5">
              <v-col>
                <!-- note list -->
                <v-card style="background: transparent" ref="note_list" flat>
                  <p class="text-h6 font-weight-medium">
                    Notes
                    <v-icon> mdi-note-edit-outline </v-icon>
                  </p>
                  <p class="mb-0 grey--text font-weight-medium">
                    Click on the text field to input your notes, you can also
                    add screenshots or image files by copy/paste or drag and
                    drop into the text field below.
                  </p>

                  <v-card-text>
                    <p
                      v-if="stg_obj.notes.length === 0"
                      class="text-center grey--text text-subtitle-1"
                    >
                      No notes available. Make a note below.
                    </p>

                    <div
                      v-for="(note, index) in stg_obj.notes"
                      :key="note.id"
                      class="my-2"
                    >
                      <v-card class="rounded-lg">
                        <v-card-text>
                          <p class="d-flex align-center mb-0">
                            <span>
                              <v-icon class="text-body-1 mr-1">
                                mdi-account-outline
                              </v-icon>
                              {{ note.user ? note.user.first_name : "" }}
                              {{ note.user ? note.user.last_name : "" }}
                            </span>

                            <span class="ml-auto">
                              <v-icon class="text-body-1 mr-1">
                                mdi-calendar-outline
                              </v-icon>
                              {{ note.createdAt | formatDate }}
                            </span>

                            <span class="ml-5">
                              <v-btn
                                title="Click to edit note"
                                @click="handle_edit_note(note, index)"
                                icon
                                small
                              >
                                <v-icon color="teal" class="text-body-1">
                                  mdi-pencil-outline
                                </v-icon>
                              </v-btn>

                              <v-btn
                                title="Click to delete"
                                @click="handle_delete_note(note.id)"
                                icon
                                small
                              >
                                <v-icon color="red"> mdi-close </v-icon>
                              </v-btn>
                            </span>
                          </p>
                        </v-card-text>

                        <v-divider />

                        <v-card-text>
                          <div v-if="note.note" style="white-space: pre">
                            {{ note.note }}
                          </div>

                          <div
                            v-if="note.content"
                            class="note-contents"
                            v-html="note.content"
                          ></div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-card-text>
                </v-card>

                <!-- note editor -->
                <v-card style="background: transparent" ref="note_editor" flat>
                  <v-card-text class="pa-1">
                    <div>
                      <RichTextEditor
                        ref="tipTapEditor"
                        type="stg_obj"
                        :id="stg_obj.id"
                        :user_id="$store.getters['Auth/getAuthUser'].id"
                        @handleSaveNote="handle_save_note"
                      />
                    </div>
                  </v-card-text>
                </v-card>

                <v-dialog v-model="note_dialog" fullscreen>
                  <v-toolbar class="sticky-toolbar orange darken-3" flat>
                    <v-toolbar-title>
                      <v-icon>mdi-image</v-icon> Image Preview
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="note_dialog = false" title="Close">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>

                  <v-card>
                    <v-card-text>
                      <v-img :src="selectedImage" aspect-ratio="1" />
                    </v-card-text>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="openAddTaskForm" max-width="800" persistent>
      <AddTaskForm
        @add_task="handle_add_task"
        @close="openAddTaskForm = false"
      />
    </v-dialog>

    <v-dialog
      v-model="update_task"
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card style="background-color: #111">
        <v-toolbar color="orange darken-3">
          <v-toolbar-title class="white--text">
            <v-icon> mdi-clipboard-edit-outline</v-icon>
            Edit Task
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="update_task = false" title="Click to close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <UpdateTask
          ref="updateTaskRef"
          v-if="temp_task_id"
          view_type="pop_up"
          :kanban_type="stg_obj.stg_itm.type"
          :stage_item_id="stg_obj.stg_itm.id"
          :stage_objective_id="stg_obj.id"
          :stage_objective_task_id="temp_task_id"
          @close="
            update_task = false;
            temp_task_id = null;
            readStageObjective();
          "
        />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import API from "@/plugins/API";
import filt from "@/plugins/filters";
import AddTaskForm from "@/components/kanban/Add_Task.vue";
import UpdateTask from "@/views/admin/kanban/objective/task/ReadTask.vue";
import RichTextEditor from "@/components/note/TipTapEditor.vue";

export default {
  props: ["kanban_type", "stage_item_id", "stage_objective_id"],

  components: {
    AddTaskForm,
    UpdateTask,
    RichTextEditor,
  },

  data: () => ({
    pageIsLoading: true,
    error: false,
    errorMessage: "",
    openAddTaskForm: false,

    objective_status_is_updating: false,
    objective_status_is_submitted: false,

    note_dialog: false,
    selectedImage: "",
    temp_objective_note_index: null,

    update_task: false,
    temp_task_id: null,

    stg_itm: {},

    stg_obj: {},

    initialState: {},

    panel: [0, 1],

    task_headers: [
      { text: "Days Passed", value: "day_count" },
      { text: "Due Date", value: "new_day_limit" },
      { text: "Subject", value: "subject" },
      { text: "Description", value: "description" },
      { text: "Status", value: "status" },
      { text: "", value: "action" },
    ],

    task_status_options: ["To Do", "On Progress", "Done", "Archived"],

    status_options: ["Active", "Closed", "Archived"],
  }),

  methods: {
    scrollToElement(element) {
      window.scrollTo({
        top: element.offsetTop - 5, // adjust the scroll position by 100px
        behavior: "smooth",
      });
    },

    getDays(created_date) {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(created_date);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },

    formatDate(date_value) {
      // create a new Date object from the string
      const dateObj = new Date(date_value);

      // extract the date components from the date object
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1; // add 1 since month is zero-based
      const day = dateObj.getDate();

      // format the date components as mm/dd/yyyy
      const formattedDate = `${month}/${day}/${year}`;

      return formattedDate;
    },

    convertObjectiveDayLimit() {
      const date = new Date(this.stg_obj.createdAt);
      date.setDate(date.getDate() + this.stg_obj.day_limit);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const result = `${year}-${month}-${day}`;
      this.stg_obj.new_day_limit = result;
    },

    convertTaskDayLimit() {
      this.stg_obj.stg_obj_tsks.forEach((task) => {
        const date = new Date(task.createdAt);
        date.setDate(date.getDate() + task.day_limit);

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const result = `${month}-${day}-${year}`;
        task.new_day_limit = result;
      });
    },

    async updateTaskStatus(task_id, status) {
      try {
        // find the task
        const task = this.stg_obj.stg_obj_tsks.find(
          (task) => task.id === task_id
        );

        // update the task status
        task.status = status;

        // update the task
        const update_task_res = await API().patch(
          "api/kanban/update_stage_objective_task",
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            stg_obj_tsk: task,
            stg_itm_id: this.stg_obj.stg_itm.id,
            note: "",
          }
        );

        console.log(update_task_res);

        if (update_task_res.status === 201) console.log(update_task_res.data);
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error;
      }
    },

    async handle_update_objective() {
      try {
        this.objective_status_is_updating = true;

        // count from created date to new_day_limit
        // strapi date format to mm/dd/yyyy
        const dateFormatted = this.formatDate(this.stg_obj.createdAt);

        // specify the start date in mm/dd/yyyy format
        const startDate = new Date(dateFormatted);

        // get the day limit
        const endDate = new Date(this.stg_obj.new_day_limit);

        // calculate the time difference between the two dates
        const timeDiff = endDate.getTime() - startDate.getTime();

        // convert the time difference to days
        const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // update the day limit
        this.stg_obj.day_limit = daysDiff;

        const update_res = await API().patch(
          "api/kanban/update_stage_objective",
          {
            user_id: this.$store.getters["Auth/getAuthUser"].id,
            stg_obj: this.stg_obj,
          }
        );

        console.log(update_res);

        if (update_res.status === 201) {
          console.log(update_res.data);

          this.objective_status_is_updating = false;
          this.objective_status_is_submitted = true;

          setTimeout(() => {
            this.objective_status_is_submitted = false;
            this.$router.go(-1);
          }, 2000);
        } else {
          this.error = true;
          this.errorMessage = "Failed to update objective";
          this.objective_status_is_updating = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
        this.objective_status_is_updating = false;
      }
    },

    async readStageObjective() {
      try {
        this.pageIsLoading = true;
        // get stage objective data
        const stg_obj_res = await API().get(
          `api/kanban/read_stage_objective/?stg_obj_id=${this.stage_objective_id}`
        );
        // console.log("stage objective: ", stg_obj_res);

        if (stg_obj_res.status === 200) {
          this.stg_obj = { ...stg_obj_res.data, new_day_limit: "" };
          this.convertObjectiveDayLimit();
          this.convertTaskDayLimit();
          this.stg_obj.task = this.stg_obj.stg_obj_tsks.length;
        }

        const read_stg_itm = await API().get(
          `api/kanban/read_stage_item/?stg_itm_id=${this.stage_item_id}`
        );

        this.stg_itm = read_stg_itm.data;

        // console.log("stage item: ", this.stg_itm);

        this.initialState = { ...this.stg_obj }; // Store initial state

        this.pageIsLoading = false;

        this.$nextTick(() => {
          if (this.$refs.note_list) {
            this.$refs.note_list.$el.addEventListener(
              "click",
              this.onNoteImageClick
            );
          }
        });
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error;
      }
    },

    handle_save_note(note, it_is_edit) {
      if (it_is_edit) {
        this.stg_obj.notes[this.temp_objective_note_index].content = note.content;
        this.temp_objective_note_index = null;

        const note_list = this.$refs.note_list.$el;
        this.scrollToElement(note_list);
      } else {
        this.stg_obj.notes.push(note);
      }
    },

    async handle_delete_note(id) {
      try {
        if (window.confirm("Are you sure you want to delete this note?")) {
          this.loadingNotes = true;
          const response = await API().post(
            `/api/internal-admin/note/delete?note_id=${id}`
          );

          console.log("Response: ", response.message);

          this.stg_obj.notes = this.stg_obj.notes.filter(
            (note) => note.id !== id
          );
        } else {
          console.log("Delete cancelled");
          return;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = `Error ${error.response.status}: ${error.response.data.message}`;
      }
    },

    handle_edit_note(note, index) {
      this.temp_objective_note_index = index;

      // set the editor content
      this.$refs.tipTapEditor.setContentToEdit(note);

      const note_editor = this.$refs.note_editor.$el;
      this.scrollToElement(note_editor);
    },

    onNoteImageClick(event) {
      if (event.target.tagName === "IMG") {
        this.selectedImage = event.target.src;
        this.note_dialog = true;
      }
    },

    async handle_add_task(data) {
      try {
        const res = await API().post("api/kanban/add_stage_objective_task", {
          objective_id: this.stage_objective_id,
          task: data,
        });

        console.log(res);

        if (res.status === 201) {
          this.stg_obj.stg_obj_tsks.push(res.data);
          this.convertTaskDayLimit();
          this.openAddTaskForm = false;
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },

    async handle_delete_task(task_id) {
      try {
        if (window.confirm("Are you sure you want to delete this task?")) {
          const res = await API().post(
            "api/kanban/delete_stage_objective_task",
            {
              id: task_id,
            }
          );

          console.log(res);

          if (res.status === 200) {
            // find the task
            const task = this.stg_obj.stg_obj_tsks.find(
              (task) => task.id === task_id
            );

            // remove the task
            this.stg_obj.stg_obj_tsks.splice(
              this.stg_obj.stg_obj_tsks.indexOf(task),
              1
            );
          }
        } else {
          console.log("cancelled");
        }
      } catch (error) {
        console.log(error);
        this.error = true;
        this.errorMessage = error.response.data.message;
      }
    },
  },

  computed: {
    hasChanges() {
      const result =
        JSON.stringify(this.initialState) !== JSON.stringify(this.stg_obj);
      return result;
    },

    stg_obj_count() {
      // strapi date format to mm/dd/yyyy
      const dateFormatted = this.formatDate(this.stg_obj.createdAt);

      // specify the start date in mm/dd/yyyy format
      const startDate = new Date(dateFormatted);

      // get the current system date
      const endDate = new Date();

      // calculate the time difference between the two dates
      const timeDiff = endDate.getTime() - startDate.getTime();

      // convert the time difference to days
      const daysDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

      return daysDiff;
    },
  },

  mounted() {
    this.readStageObjective();
  },

  filters: {
    ...filt,
  },
};
</script>

<style lang="scss">
.bordered {
  border: 1px solid #3c3c3c !important;
  background-color: transparent !important;
  padding: 5px;
}

.task-style {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

/* height */
.task-style::-webkit-scrollbar {
  height: 10px;
}

/* Track */
.task-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

/* Handle */
.task-style::-webkit-scrollbar-thumb {
  background: rgb(97, 97, 97);
  border-radius: 10px;
}

/* Handle on hover */
.task-style::-webkit-scrollbar-thumb:hover {
  background: rgb(49, 49, 49);
}

.circle .v-input--selection-controls__ripple {
  border-radius: 50%;
}

.circle .v-input--selection-controls__input {
  border-radius: 50%;
}

.circle .v-icon {
  border-radius: 50%;
}

.custom-text-field input {
  border: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
  outline: none;
}

.note-contents {
  p {
    img {
      max-width: 100%;  
      height: auto;
      cursor: pointer;
    }
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;

    td,
    th {
      min-width: 1em;
      border: 2px solid #444;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      font-weight: bold;
      text-align: left;
      background-color: #444;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }

    p {
      margin: 0;
    }
  }
}
</style>
